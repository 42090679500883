/* frontend/src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles if needed */

.App {
  min-height: 100vh; /* Ensure the App takes at least the full viewport height */
  display: flex;
  flex-direction: column;
}

.main-content {
  /* This property makes the element grow to fill available space */
  /* It allows the main content to expand and push the footer to the bottom */
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.log-container {
  margin-bottom: 20px;
}

.log-row {
  display: flex;
  justify-content: space-between;
}

.log-item {
  margin-bottom: 10px;
}

.status-message-container {
  min-height: 20px;
  margin-bottom: 10px;
}

.button-container {
  margin-bottom: 20px;
}

.file-size {
  margin-bottom: 10px;
}

.logs {
  text-align: left;
}

.log-entry {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  text-align: center;
}

.footer p {
  margin: 5px 0;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Transition for smooth theme switching */
body, .App {
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Add these styles to prevent body scrolling */
body {
  overflow: hidden;
}

/* Add styles for the log section */
.notes {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;             /* Chrome, Safari and Opera */
}

/* Also add these rules to ensure the notes container hides scrollbars */
.notes {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.notes::-webkit-scrollbar {
  display: none;
}

/* General dropdown styles (centered) */
select {
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
  -webkit-text-align-last: center;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M17 8l-5-5-5 5M17 16l-5 5-5-5'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0rem center;
  background-size: 1em 1em;
  padding-right: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Header-specific dropdown styles (right-aligned) */
select.header-select,
.compact-select {
  text-align: right !important;
  text-align-last: right !important;
  -webkit-text-align: right !important;
  -webkit-text-align-last: right !important;
  direction: rtl !important;
  padding-right: 2rem !important;
  padding-left: 1rem !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M17 8l-5-5-5 5M17 16l-5 5-5-5'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.5rem center !important;
  background-size: 1em 1em !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

select.header-select option,
.compact-select option {
  text-align: right !important;
  text-align-last: right !important;
  direction: rtl !important;
  unicode-bidi: plaintext !important;
  padding-right: 1rem !important;
}

/* Remove the scrollbar styles since we're not showing multiple options */
select.header-select::-webkit-scrollbar,
.compact-select::-webkit-scrollbar {
  display: none;
}

select.header-select,
.compact-select {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Browser-specific styles for header dropdown */
@-moz-document url-prefix() {
  select.header-select,
  .compact-select {
    text-align: right !important;
    text-align-last: right !important;
  }
  
  select.header-select option,
  .compact-select option {
    text-align: right !important;
    text-align-last: right !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select.header-select,
  .compact-select {
    text-align: right !important;
    text-align-last: right !important;
  }
  
  select.header-select option,
  .compact-select option {
    text-align: right !important;
    text-align-last: right !important;
  }
}

/* Style the scrollbar for Webkit browsers */
select.header-select::-webkit-scrollbar,
.compact-select::-webkit-scrollbar {
  width: 6px;
}

select.header-select::-webkit-scrollbar-track,
.compact-select::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

select.header-select::-webkit-scrollbar-thumb,
.compact-select::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

select.header-select::-webkit-scrollbar-thumb:hover,
.compact-select::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  select.header-select,
  .compact-select {
    max-height: 40vh; /* Slightly smaller on mobile */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }
}

/* Add styles for the select container */
.header-select-container {
  display: flex;
  justify-content: flex-end;
  width: auto;
}

/* General form field styles */
input[type="text"],
input[type="number"],
select:not(.header-select) {
  text-align: center !important;
}

/* Form labels */
label,
.form-label {
  text-align: left !important;
}

/* Ensure iOS doesn't override our text alignment */
@supports (-webkit-touch-callout: none) {
  select.header-select,
  select.header-select option,
  .compact-select,
  .compact-select option {
    text-align: right !important;
    text-align-last: right !important;
  }
  
  input[type="text"],
  input[type="number"],
  select:not(.header-select) {
    text-align: center !important;
  }
}

/* iPad-specific styles */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) {
  .app-header {
    @apply py-1.5 px-3;
  }

  .app-title {
    @apply text-base;
  }

  .header-icons {
    @apply space-x-3;
  }

  .header-icon {
    @apply w-8 h-8 p-1.5;
  }

  /* Adjust main content padding for iPad */
  .main-content {
    @apply p-4;
  }

  /* Adjust grid layouts for iPad */
  .calculator-grid {
    @apply grid-cols-2;
  }

  /* Add these new styles */
  .compact-select {
    background-position: right 0.5rem center !important;
    padding-right: 2rem !important;
    padding-left: 1rem !important;
  }

  .compact-select option {
    text-align: right !important;
    text-align-last: right !important;
    direction: rtl !important;
    unicode-bidi: plaintext !important;
    padding-right: 1rem !important;
  }

  /* Icon grid for tablet */
  .icon-grid {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
  }

  .icon-grid a {
    padding: 0.5rem;
  }
}

/* Add specific styles for iPad in portrait mode */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) {
  .app-title {
    @apply hidden sm:block;
  }

  .compact-select {
    max-width: 300px;
  }
}

/* Add specific styles for iPad in landscape mode */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: landscape) {
  .app-title {
    @apply block;
  }
}

/* Legacy mobile device support */
@media only screen 
and (max-width: 767px) {
  select.header-select,
  .compact-select {
    background-position: right 0.5rem center !important;
    padding-right: 2rem !important;
    padding-left: 1rem !important;
  }

  select.header-select option,
  .compact-select option {
    text-align: right !important;
    text-align-last: right !important;
    direction: rtl !important;
    unicode-bidi: plaintext !important;
    padding-right: 1rem !important;
  }
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
  select.header-select,
  .compact-select {
    background-position: right 0.5rem center !important;
    padding-right: 2rem !important;
    padding-left: 1rem !important;
  }

  select.header-select option,
  .compact-select option {
    text-align: right !important;
    text-align-last: right !important;
    direction: rtl !important;
    unicode-bidi: plaintext !important;
    padding-right: 1rem !important;
  }
}