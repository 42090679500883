/* frontend/src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@media (max-width: 75%) {
  .copied-message {
    display: none;
  }
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 0%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: -webkit-fill-available;
}

.App-header {
  text-align: center;
  margin-bottom: 1%;
}

.App-header h1 {
  font-size: 2.5rem;
  color: #333;
}

/* Add these styles to your existing index.css */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

/* Add these styles for flexible scrolling container */
.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 0%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: -webkit-fill-available;
}

/* Ensure the log container grows and shrinks properly */
@media (max-width: 768px) {
  .scrollbar-hide {
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
    height: auto;
    max-height: 60vh; /* Adjust this value as needed */
  }

  /* Ensure proper viewport height on mobile */
  .App {
    height: 100%;
    min-height: -webkit-fill-available;
  }
}

/* Remove any fixed height constraints */
.flex-1 {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;             /* Chrome, Safari and Opera */
}
